.minWidthXs {
    min-width: 110px;
}
.minWidthSm {
    min-width: 180px;
}
.minWidthMd {
    min-width: 214px;
}
.minWidthLg {
    min-width: 332px;
}
