@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom base styles & overrides */

@layer base {
    /**
     * FONTS & TEXTS VARIABLES
     * YEMA Design Kit
     */
    $url-fonts: 'https://storage.googleapis.com/yema-assets/fonts/';

    @font-face {
        font-family: 'Ballinger Mono';
        font-display: swap;
        src: local('Ballinger Mono'), local('Ballinger Mono Light'), local('BallingerMonoLight'),
            local('Ballinger-Mono-Light'), local('BallingerMono-Light'),
            url('#{$url-fonts}BallingerMono-Light.eot');
        src: url('#{$url-fonts}BallingerMono-Light.woff') format('woff'),
            url('#{$url-fonts}BallingerMono-Light.woff2') format('woff2'),
            url('#{$url-fonts}BallingerMono-Light.ttf') format('truetype'),
            url('#{$url-fonts}BallingerMono-Light.svg') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Ballinger Mono';
        font-display: swap;
        src: local('Ballinger Mono'), local('Ballinger Mono Regular'), local('BallingerMonoRegular'),
            local('Ballinger-Mono-Regular'), local('BallingerMono-Regular'),
            url('#{$url-fonts}BallingerMono-Regular.eot');
        src: url('#{$url-fonts}BallingerMono-Regular.woff') format('woff'),
            url('#{$url-fonts}BallingerMono-Regular.woff2') format('woff2'),
            url('#{$url-fonts}BallingerMono-Regular.ttf') format('truetype'),
            url('#{$url-fonts}BallingerMono-Regular.svg') format('svg');
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: 'Ballinger Mono';
        font-display: swap;
        src: local('Ballinger Mono'), local('Ballinger Mono Medium'), local('BallingerMonoMedium'),
            url('#{$url-fonts}BallingerMono-Medium.eot');
        src: url('#{$url-fonts}BallingerMono-Medium.woff') format('woff'),
            url('#{$url-fonts}BallingerMono-Medium.woff2') format('woff2'),
            url('#{$url-fonts}BallingerMono-Medium.ttf') format('truetype'),
            url('#{$url-fonts}BallingerMono-Medium.svg') format('svg');
        font-weight: 900;
        font-style: normal;
    }

    body {
        @apply font-body text-normal font-body-regular text-ui-black;
    }

    a.underline {
        &:hover {
            text-decoration: underline !important;
        }
    }

    p {
        @apply mb-4;
    }

    p,
    ul,
    li {
        @apply font-body text-normal font-body-regular;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply mb-2 font-heading;
    }
    h1 {
        @apply font-heading-regular;
        @apply text-h2 md:text-h1;
    }
    h2 {
        @apply font-heading-regular;
        @apply text-h3 md:text-h2;
    }
    h3 {
        @apply font-heading-medium;
        @apply text-h3;
    }
    h4 {
        @apply font-heading-medium;
        @apply text-h4;
    }
    h5 {
        @apply font-heading-medium;
        @apply text-h5;
    }
    h6 {
        @apply font-heading-medium;
        @apply text-h6;
    }

    img {
        max-width: initial;
        vertical-align: middle;
        border-style: none; /* Remove the border on images inside links in IE 10- */
    }

    img,
    svg,
    video,
    canvas,
    audio,
    iframe,
    embed,
    object {
        display: initial;
    }
}

@layer components {
    .container-fluid {
        width: 100%;
        padding-right: 2rem;
        padding-left: 2rem;
        margin-right: auto;
        margin-left: auto;
        @media (max-width: 767px) {
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: calc(-1 * 30px / 2);
        margin-left: calc(-1 * 30px / 2);
    }

    @keyframes spinner-border {
        to {
            transform: rotate(360deg);
        }
    }
    .spinner-border {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        vertical-align: text-bottom;
        border: 2rem solid currentColor;
        border-right-color: transparent;
        border-radius: 50%;
        animation: spinner-border 0.75s linear infinite;
    }
    .spinner-border-sm {
        width: 1rem;
        height: 1rem;
        border-width: 0.2rem;
    }

    .rich-text {
        .richtext-image {
            max-width: 100%;
            height: auto;

            &.right {
                float: right;
            }

            &.left {
                float: left;
            }

            &.full-width {
                margin: auto;
                display: block;
            }
        }

        .responsive-object {
            padding-bottom: 30px !important;
            text-align: center;

            @media (max-width: 640px) {
                & > iframe {
                    width: 100%;
                    min-height: 240px;
                    height: 100%;
                }
            }
        }

        ul {
            list-style: disc;
            padding-left: 25px;
        }
    }

    .text-muted {
        color: #6c757d;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input {
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    &[type='number'] {
        -moz-appearance: textfield;
        &::-webkit-inner-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
    &:focus {
        outline: none;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
}

.highlight-card-animation {
    &[animate='1'] {
        animation-duration: 3000ms;
        -webkit-animation-duration: 3000ms;

        animation-timing-function: ease;
        -webkit-animation-timing-function: ease;

        visibility: visible !important;
    }

    @keyframes highlight {
        0% {
            background-color: #fcfcfc;
        }
        20%,
        80% {
            background-color: #fdf2f1;
        }
        100% {
            background-color: #fcfcfc;
        }
    }
}
.clickable-card-pos-animation {
    &[animate='1'] {
        animation-name: expandOpen;
        -webkit-animation-name: expandOpen;

        animation-duration: 300ms;
        -webkit-animation-duration: 300ms;

        animation-timing-function: ease-out;
        -webkit-animation-timing-function: ease-out;

        visibility: visible !important;
    }

    @keyframes expandOpen {
        0% {
            transform: scale(0.98);
        }
        50% {
            transform: scale(0.96);
        }
        100% {
            transform: scale(1);
        }
    }

    @-webkit-keyframes expandOpen {
        0% {
            -webkit-transform: scale(0.98);
        }
        50% {
            -webkit-transform: scale(0.96);
        }
        100% {
            -webkit-transform: scale(1);
        }
    }
}

//Tooltips
[id^='aria-tooltip-'] {
    z-index: 2000 !important;
    pointer-events: auto !important;
}

// Override third party styles
.Toastify__toast-theme--light {
    background: none !important;
}

.ReactModal__Body--open {
    overflow: hidden !important;
    height: 100vh;
    margin: 0;
}

// POS Receipt
.yds-receipt {
    .totals {
        font-size: 12px !important;
        & > div {
            margin-bottom: 5px;
        }
        & > div > div:last-child {
            min-width: 120px;
        }
    }
}
