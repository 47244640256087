.overlay {
    background-color: rgba(0, 0, 0, 0.6);
    transition: visibility 0s, opacity 0.5s linear;
    z-index: 1000;
}

.animateBlob1 {
    animation: animateBlob1 3s linear infinite;
}
.animateBlob2 {
    animation: animateBlob2 3s linear infinite;
}
.animateBlob3 {
    animation: animateBlob3 3s linear infinite;
}
.animateBlob4 {
    animation: animateBlob4 3s linear infinite;
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

@keyframes animateBlob1 {
    0% {
        opacity: 0;
    }
    8.25% {
        opacity: 100%;
    }
    66% {
        opacity: 100%;
    }
    74.5% {
        opacity: 75%;
    }
    83% {
        opacity: 50%;
    }
    91.5% {
        opacity: 25%;
    }
    100% {
        opacity: 0;
    }
}

@keyframes animateBlob2 {
    0% {
        opacity: 0;
    }
    8.25% {
        opacity: 0;
    }
    16.5% {
        opacity: 0;
    }
    24.75% {
        opacity: 0;
    }
    33% {
        opacity: 100%;
    }
    66% {
        opacity: 100%;
    }
    74.5% {
        opacity: 75%;
    }
    83% {
        opacity: 50%;
    }
    91.5% {
        opacity: 25%;
    }
    100% {
        opacity: 0;
    }
}

@keyframes animateBlob3 {
    0% {
        opacity: 0;
    }
    8.25% {
        opacity: 0;
    }
    16.5% {
        top: 0;
        left: 50%;
        opacity: 100%;
    }
    24.75% {
        top: 50%;
        left: 50%;
        opacity: 100%;
    }
    33% {
        top: 50%;
        left: 50%;
        opacity: 100%;
    }
    66% {
        top: 50%;
        left: 50%;
        opacity: 100%;
    }
    74.5% {
        top: 50%;
        left: 50%;
        opacity: 75%;
    }
    83% {
        top: 50%;
        left: 50%;
        opacity: 50%;
    }
    91.5% {
        top: 50%;
        left: 50%;
        opacity: 25%;
    }
    100% {
        top: 50%;
        left: 50%;
        opacity: 0;
    }
}

@keyframes animateBlob4 {
    0% {
        top: 0;
        left: 0;
        opacity: 100%;
    }
    8.25% {
        top: 0;
        left: 50%;
        opacity: 100%;
    }
    16.5% {
        top: 50%;
        left: 50%;
        opacity: 100%;
    }
    24.75% {
        top: 50%;
        left: 0;
        opacity: 100%;
    }
    33% {
        top: 50%;
        left: 0;
        opacity: 100%;
    }
    66% {
        top: 50%;
        left: 0;
        opacity: 100%;
    }
    74.5% {
        top: 50%;
        left: 0;
        opacity: 75%;
    }
    83% {
        top: 50%;
        left: 0;
        opacity: 50%;
    }
    91.5% {
        top: 50%;
        left: 0;
        opacity: 25%;
    }
    100% {
        top: 50%;
        left: 0;
        opacity: 0;
    }
}
